const Airport = ({ className }) => (
    <svg className={className} width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <g>
                <path d="M18.0909 1.771L14.7742 3.67272C14.7185 3.70468 14.6521 3.71324 14.59 3.69651L5.01581 1.11755C4.89159 1.0841 4.75908 1.10121 4.64762 1.16512L3.47137 1.83954C3.31274 1.93049 3.30968 2.1568 3.4658 2.25195L9.53087 5.94825C9.82474 6.12741 9.84069 6.54585 9.56108 6.74649L5.58417 9.60033C5.43733 9.70569 5.24349 9.72069 5.082 9.63909L2.24954 8.20869C2.17665 8.17185 2.08996 8.17413 2.01916 8.21481L1.23317 8.66541C1.10799 8.73717 1.07383 8.90133 1.16012 9.01653L4.11323 12.9585C4.18317 13.0519 4.3074 13.0834 4.41198 13.031C5.42071 12.5248 10.4902 9.95733 13.6728 7.90029C17.2201 5.60757 21.378 3.80584 22.4009 1.3782C21.7968 0.338968 18.0909 1.771 18.0909 1.771Z" fill="#797882" stroke="#797882" />
                <path d="M1 18.4434H22.75" stroke="#797882" strokeLinecap="round" />
            </g>
        </g>
    </svg>
);

export default Airport;
