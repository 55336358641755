import { DestinationTypeEnum } from "@/static/contants";

export class Type {
    static mappedTypes = new Map([
        [DestinationTypeEnum.POI, 'Attraction'],
        [DestinationTypeEnum.VIEWPORT, 'Attraction']
    ]);

    static getType(type) {
        if (this.mappedTypes.has(type)) {
            return this.mappedTypes.get(type);
        }

        return type;
    }
}
