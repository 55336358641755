// import { useEffect, useState } from "react"
// import { isMobile, isTablet, isDesktop } from  "@/utils/commonScripts/commonMethods"
// import { ObjectShallowEqualityCheck } from "@/utils/globalFunc"
import { useSelector } from "react-redux";
import useResponsiveResource from "./useResponsiveResource";
import { constants } from "../constants";


const useDeviceDetect = () => {
    const device = useSelector(state => state.device);
    return device;
};

export default useDeviceDetect;

const useDeviceDetectWithoutRedux = () =>
    useResponsiveResource(
        new Map()
            .set(`(max-width: ${constants.mobile_max_width}px)`, {
                isMobile: true,
                isTablet: false,
                isDesktop: false
            })
            .set(`(min-width: ${constants.mobile_max_width + 1}px)`, {
                isMobile: false,
                isTablet: true,
                isDesktop: false
            })
            .set(`(min-width: ${constants.tablet_max_width}px)`, {
                isMobile: false,
                isTablet: false,
                isDesktop: true
            })
    ) ?? {};

export {
    useDeviceDetectWithoutRedux as useDeviceDetect
};

//  talented programmer Sarmadbilal wrote this wonderful code:
/*
export default function useDeviceDetect () {

    const [devices, setDevices] = useState({
        isMobile: false,
        isTablet: false,
        isDesktop: false
    })


    useEffect(()=>{

        setDevices({
            isMobile: isMobile(),
            isTablet: isTablet(),
            isDesktop: isDesktop()
        })

        window.addEventListener('resize',()=>{

            let obj = {
                        isMobile: isMobile(),
                        isTablet: isTablet(),
                        isDesktop: isDesktop()
                    };

            if(!ObjectShallowEqualityCheck(devices, obj) )
            {
                setDevices(obj)
            }
        });

    },[])

    return devices
}
*/