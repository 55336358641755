import { constants } from '@/src/utils/constants';
import React, { memo } from 'react';

import { DateRangePicker, DayPickerRangeController, SingleDatePicker } from 'react-dates';

const DateRangePickerWrapper = memo(props => (
    <DateRangePicker
        firstDayOfWeek={constants.firstDayOfWeek}
        {...props}
    />
));


const DayPickerRangeControllerWrapper = memo(props => (
    <DayPickerRangeController
        firstDayOfWeek={constants.firstDayOfWeek}
        {...props}
    />
));

const SingleDatePickerWrapper = memo(props => (
    <SingleDatePicker
        firstDayOfWeek={constants.firstDayOfWeek}
        {...props}
    />
));

export {
    DateRangePickerWrapper as default,
    DayPickerRangeControllerWrapper as DayPickerRangeController,
    SingleDatePickerWrapper as SingleDatePicker
};
