
const LogoBirdsText = () => {
    return (
        <svg width="202" height="45" viewBox="0 0 202 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M72.2678 35.1479C71.6822 35.8835 70.9739 36.544 70.1426 37.1286C69.4248 37.6192 68.537 38.0722 67.479 38.487C66.4213 38.9021 65.1838 39.1098 63.767 39.1098C62.2369 39.1098 60.8153 38.8361 59.5024 38.289C58.1897 37.7417 57.0513 37.0015 56.0881 36.0674C55.1246 35.1337 54.369 34.0394 53.8212 32.7847C53.2732 31.5303 52.9995 30.1953 52.9995 28.7806C52.9995 27.3655 53.2732 26.0307 53.8212 24.7762C54.369 23.5216 55.1246 22.4273 56.0881 21.4934C57.0513 20.5595 58.1897 19.8191 59.5024 19.272C60.8153 18.7249 62.2369 18.4512 63.767 18.4512C65.146 18.4512 66.3598 18.6587 67.4081 19.0739C68.4566 19.4888 69.3304 19.9418 70.0294 20.4321C70.8415 21.0172 71.5405 21.6775 72.1261 22.4131L69.2926 25.243C68.877 24.7149 68.3951 24.2431 67.8475 23.8281C67.3751 23.4695 66.799 23.1441 66.1191 22.8518C65.4389 22.5595 64.6548 22.4131 63.767 22.4131C62.8792 22.4131 62.0479 22.5781 61.2735 22.9085C60.4989 23.2386 59.8236 23.6914 59.2475 24.2667C58.6714 24.8421 58.218 25.5165 57.8873 26.2901C57.5567 27.0637 57.3915 27.8938 57.3915 28.7806C57.3915 29.667 57.5567 30.4973 57.8873 31.2707C58.218 32.0445 58.6714 32.7187 59.2475 33.2941C59.8236 33.8696 60.4989 34.3224 61.2735 34.6525C62.0479 34.9827 62.8792 35.1479 63.767 35.1479C64.6926 35.1479 65.505 35.0015 66.204 34.7092C66.9029 34.4167 67.498 34.0913 67.9892 33.7329C68.5558 33.3177 69.0375 32.8461 69.4341 32.3178L72.2678 35.1479Z" fill="#4C35BC" />
            <path d="M72.9351 24.793H76.7603V32.5754C76.7603 33.4242 76.9871 34.0846 77.4404 34.5561C77.8938 35.0279 78.517 35.2637 79.3106 35.2637C80.1608 35.2637 80.8691 34.976 81.4356 34.4005C82.0024 33.825 82.286 33.0752 82.286 32.1506V24.793H86.1112V38.6594H82.286V36.6786H82.1441C81.8419 37.0937 81.4924 37.471 81.0957 37.8105C80.7557 38.0936 80.3259 38.353 79.8064 38.5888C79.2869 38.8246 78.6965 38.9426 78.0355 38.9426C77.3176 38.9426 76.6517 38.8056 76.0378 38.5322C75.4238 38.2587 74.8855 37.8719 74.4226 37.3721C73.9599 36.872 73.5962 36.2636 73.3317 35.5465C73.0673 34.8299 72.9351 34.0279 72.9351 33.1412V24.793Z" fill="#4C35BC" />
            <path d="M94.9278 35.4217C95.4191 35.4217 95.8818 35.3272 96.3162 35.1387C96.7508 34.9501 97.1284 34.6953 97.4498 34.3746C97.771 34.054 98.021 33.667 98.2005 33.2144C98.38 32.7614 98.4698 32.2711 98.4698 31.7428C98.4698 31.2145 98.38 30.7239 98.2005 30.2713C98.021 29.8183 97.771 29.4317 97.4498 29.1108C97.1284 28.7902 96.7508 28.5356 96.3162 28.3469C95.8818 28.1581 95.4191 28.0638 94.9278 28.0638C94.4366 28.0638 93.9739 28.1581 93.5393 28.3469C93.1049 28.5356 92.7271 28.7902 92.4059 29.1108C92.0847 29.4317 91.8345 29.8183 91.655 30.2713C91.4755 30.7239 91.3859 31.2145 91.3859 31.7428C91.3859 32.2711 91.4755 32.7614 91.655 33.2144C91.8345 33.667 92.0847 34.054 92.4059 34.3746C92.7271 34.6953 93.1049 34.9501 93.5393 35.1387C93.9739 35.3272 94.4366 35.4217 94.9278 35.4217ZM98.3283 36.6951H98.1864C97.903 37.1102 97.5442 37.4875 97.1098 37.8272C96.7318 38.11 96.2689 38.3696 95.7213 38.6052C95.1734 38.8412 94.5312 38.9589 93.7944 38.9589C92.9822 38.9589 92.1981 38.7845 91.4424 38.4354C90.687 38.0864 90.0209 37.5912 89.4448 36.9499C88.8686 36.3085 88.4105 35.5442 88.0705 34.6576C87.7305 33.7708 87.5605 32.7992 87.5605 31.7428C87.5605 30.6862 87.7305 29.7147 88.0705 28.8279C88.4105 27.9411 88.8686 27.1818 89.4448 26.5498C90.0209 25.9179 90.687 25.4227 91.4424 25.0642C92.1981 24.7057 92.9822 24.5264 93.7944 24.5264C94.5312 24.5264 95.1734 24.6443 95.7213 24.8803C96.2689 25.1161 96.7318 25.3755 97.1098 25.6583C97.5442 25.9979 97.903 26.3754 98.1864 26.7904H98.3283V18.8667H102.153V38.6761H98.3283V36.6951Z" fill="#4C35BC" />
            <path d="M111.063 35.4217C111.554 35.4217 112.017 35.3272 112.452 35.1387C112.886 34.9501 113.264 34.6953 113.585 34.3746C113.906 34.054 114.156 33.667 114.336 33.2144C114.515 32.7614 114.605 32.2711 114.605 31.7428C114.605 31.2145 114.515 30.7239 114.336 30.2713C114.156 29.8183 113.906 29.4317 113.585 29.1108C113.264 28.7902 112.886 28.5356 112.452 28.3469C112.017 28.1581 111.554 28.0638 111.063 28.0638C110.572 28.0638 110.109 28.1581 109.675 28.3469C109.24 28.5356 108.862 28.7902 108.541 29.1108C108.22 29.4317 107.97 29.8183 107.79 30.2713C107.611 30.7239 107.521 31.2145 107.521 31.7428C107.521 32.2711 107.611 32.7614 107.79 33.2144C107.97 33.667 108.22 34.054 108.541 34.3746C108.862 34.6953 109.24 34.9501 109.675 35.1387C110.109 35.3272 110.572 35.4217 111.063 35.4217ZM114.464 36.6951H114.322C114.038 37.1102 113.68 37.4875 113.245 37.8272C112.867 38.11 112.404 38.3696 111.857 38.6052C111.309 38.8412 110.666 38.9589 109.93 38.9589C109.117 38.9589 108.334 38.7845 107.578 38.4354C106.822 38.0864 106.156 37.5912 105.58 36.9499C105.004 36.3085 104.546 35.5442 104.206 34.6576C103.866 33.7708 103.696 32.7992 103.696 31.7428C103.696 30.6862 103.866 29.7147 104.206 28.8279C104.546 27.9411 105.004 27.1818 105.58 26.5498C106.156 25.9179 106.822 25.4227 107.578 25.0642C108.334 24.7057 109.117 24.5264 109.93 24.5264C110.666 24.5264 111.309 24.6443 111.857 24.8803C112.404 25.1161 112.867 25.3755 113.245 25.6583C113.679 25.9979 114.038 26.3754 114.322 26.7904H114.464V18.8667H118.289V38.6761H114.464V36.6951Z" fill="#4C35BC" />
            <path d="M124.697 18.8667H120.872V38.6763H124.697V18.8667Z" fill="#4C35BC" />
            <path d="M126.222 40.6408C126.354 40.6973 126.505 40.7445 126.675 40.7823C126.996 40.8766 127.365 40.9236 127.78 40.9236C128.422 40.9236 128.933 40.7633 129.31 40.4427C129.688 40.122 129.981 39.7162 130.189 39.2258L130.472 38.5183L125.088 24.793H129.197L132.456 33.9903H132.597L135.714 24.793H139.823L134.156 39.7919C133.57 41.3574 132.848 42.5509 131.988 43.3717C131.129 44.1923 130.057 44.6027 128.772 44.6027C128.413 44.6027 128.078 44.5837 127.766 44.546C127.454 44.5083 127.185 44.461 126.959 44.4045C126.694 44.329 126.449 44.2537 126.222 44.178V40.6408Z" fill="#4C35BC" />
            <path d="M140.807 18.8667H144.633L154.267 31.1769V18.8667H158.517V38.6761H154.692L145.058 26.3661V38.6761H140.807V18.8667Z" fill="#FA368B" />
            <path d="M170.839 30.3857C170.631 29.7443 170.258 29.1829 169.72 28.7019C169.182 28.2208 168.516 27.9802 167.722 27.9802C166.929 27.9802 166.263 28.2208 165.724 28.7019C165.186 29.1829 164.813 29.7443 164.605 30.3857H170.839ZM173.956 36.753C173.54 37.1682 173.03 37.5455 172.426 37.8851C171.916 38.1681 171.293 38.4275 170.556 38.6633C169.819 38.8991 168.969 39.017 168.005 39.017C166.986 39.017 166.027 38.8282 165.129 38.4511C164.232 38.0738 163.448 37.555 162.778 36.8947C162.107 36.2342 161.583 35.4703 161.205 34.6024C160.827 33.7346 160.638 32.8005 160.638 31.8006C160.638 30.8197 160.827 29.8905 161.205 29.0132C161.583 28.1358 162.102 27.3673 162.763 26.707C163.425 26.0466 164.19 25.5278 165.059 25.1505C165.928 24.773 166.863 24.5845 167.864 24.5845C168.827 24.5845 169.729 24.7684 170.57 25.1364C171.411 25.5042 172.143 25.9994 172.766 26.622C173.389 27.2446 173.885 27.9755 174.254 28.8151C174.622 29.6547 174.806 30.5555 174.806 31.5178C174.806 31.7818 174.797 32.013 174.778 32.211C174.759 32.4093 174.74 32.5837 174.721 32.7347C174.702 32.9232 174.683 33.0837 174.665 33.2157H164.605C164.813 33.8571 165.215 34.4185 165.809 34.8994C166.405 35.3804 167.231 35.6211 168.289 35.6211C168.893 35.6211 169.422 35.5456 169.876 35.3948C170.329 35.2438 170.716 35.0645 171.037 34.857C171.396 34.6307 171.708 34.3664 171.972 34.0646L173.956 36.753Z" fill="#FA368B" />
            <path d="M177.114 34.0646C177.397 34.3666 177.728 34.6307 178.106 34.8572C178.427 35.0645 178.814 35.2438 179.267 35.3948C179.721 35.5457 180.231 35.6211 180.798 35.6211C181.44 35.6211 181.884 35.5268 182.129 35.3382C182.375 35.1495 182.498 34.9135 182.498 34.6307C182.498 34.3666 182.323 34.145 181.974 33.9657C181.624 33.7864 181.185 33.6118 180.656 33.442C180.127 33.2723 179.56 33.0742 178.956 32.8477C178.351 32.6214 177.785 32.3384 177.256 31.9989C176.727 31.6591 176.288 31.2348 175.938 30.7254C175.589 30.2161 175.414 29.5839 175.414 28.8294C175.414 28.2634 175.537 27.7256 175.782 27.2163C176.028 26.707 176.382 26.2542 176.845 25.8579C177.308 25.4616 177.884 25.1503 178.573 24.924C179.263 24.6977 180.052 24.5845 180.94 24.5845C181.827 24.5845 182.607 24.6977 183.277 24.924C183.948 25.1503 184.51 25.3958 184.963 25.6598C185.492 25.9804 185.945 26.3295 186.323 26.707L184.056 29.1123C183.83 28.9048 183.556 28.716 183.235 28.5462C182.97 28.3954 182.644 28.2634 182.257 28.1502C181.87 28.0369 181.431 27.9804 180.94 27.9804C180.448 27.9804 180.089 28.07 179.863 28.2493C179.636 28.4283 179.523 28.6217 179.523 28.8294C179.523 29.0935 179.697 29.3152 180.047 29.4944C180.396 29.6737 180.835 29.8481 181.364 30.0179C181.893 30.1877 182.46 30.3859 183.065 30.6122C183.669 30.8385 184.236 31.1215 184.765 31.4611C185.294 31.8006 185.733 32.2252 186.082 32.7347C186.432 33.244 186.607 33.8759 186.607 34.6307C186.607 35.2343 186.484 35.8003 186.238 36.3286C185.993 36.8569 185.624 37.319 185.133 37.7153C184.642 38.1115 184.038 38.4275 183.32 38.6633C182.602 38.8991 181.761 39.0171 180.798 39.0171C179.834 39.0171 178.984 38.8849 178.247 38.6209C177.511 38.3566 176.887 38.0643 176.377 37.7435C175.773 37.385 175.263 36.9605 174.847 36.4701L177.114 34.0646Z" fill="#FA368B" />
            <path d="M188.863 28.6247H186.454V25.0873H187.729C188.768 25.0873 189.288 24.5214 189.288 23.3893V20.8423H192.688V25.0873H195.805V28.6247H192.688V33.294C192.688 33.9355 192.853 34.4259 193.184 34.7655C193.514 35.1052 193.963 35.275 194.53 35.275C194.945 35.275 195.314 35.2277 195.635 35.1335C195.805 35.0957 195.956 35.0485 196.088 34.9919V38.5294C195.862 38.6049 195.616 38.6802 195.352 38.7557C195.125 38.8124 194.856 38.8594 194.544 38.8972C194.232 38.9349 193.897 38.9539 193.538 38.9539C192.121 38.9539 190.988 38.5152 190.138 37.6379C189.288 36.7606 188.863 35.5013 188.863 33.8599V28.6247Z" fill="#FA368B" />
            <path d="M196.917 36.7252C196.917 36.0839 197.134 35.5461 197.568 35.1123C198.003 34.6782 198.541 34.4614 199.183 34.4614C199.826 34.4614 200.364 34.6782 200.799 35.1123C201.233 35.5461 201.45 36.0839 201.45 36.7252C201.45 37.3668 201.233 37.9046 200.799 38.3384C200.364 38.7724 199.826 38.9892 199.183 38.9892C198.541 38.9892 198.003 38.7724 197.568 38.3384C197.134 37.9046 196.917 37.3668 196.917 36.7252Z" fill="#4C35BC" />
            <path d="M41.124 17.3481C41.124 17.3481 43.762 20.0787 48.5228 21.1679C51.4054 16.6576 57.2993 14.14 63.6218 13.2178C56.6202 12.7458 53.8444 13.6701 52.3917 13.8263C50.7375 14.0042 46.0625 10.3875 41.124 17.3481Z" fill="#FA368B" />
            <path d="M23.9201 22.4019C23.9201 22.4019 21.556 25.3517 20.0005 28.5452C21.0882 26.8971 22.4186 25.365 25.6502 23.6941L23.9201 22.4019Z" fill="#4C35BC" />
            <path d="M17.545 10.6686C18.5395 11.6207 19.1889 12.4439 19.7944 13.327C16.973 11.9115 12.0502 11.9816 8.91406 12.4058C10.661 11.5922 14.6537 10.515 17.545 10.6686Z" fill="#4C35BC" />
            <path d="M35.2889 10.0864C35.2889 10.0864 36.978 11.1812 37.7391 13.3161C39.1997 10.4178 44.759 8.02422 48.5162 7.07868C44.2633 7.22469 42.6459 7.95308 41.7789 8.13686C40.7923 8.34594 36.859 6.95335 35.2889 10.0864Z" fill="#4C35BC" />
            <path d="M15.0639 39.139C15.0639 39.139 23.4946 31.4902 32.6666 31.4902C42.9815 31.4902 45.9616 26.5324 48.335 21.6937C48.335 21.6937 40.3826 20.6323 36.7752 12.2468C36.7752 12.2468 28.1743 0.270049 4.93388 1.01907C4.93388 1.01907 18.121 8.74717 21.3323 13.9448C24.6239 19.2722 33.1366 25.0638 32.7765 24.8864C25.3218 21.2216 20.2515 14.2577 20.0798 13.8103C19.9083 13.3629 12.8385 11.3013 0.481934 15.454C0.481934 15.454 13.759 12.8871 28.8992 25.5444C28.8992 25.5444 27.3684 26.2312 26.271 26.9886C19.4842 31.672 15.0467 39.1433 15.0639 39.139Z" fill="#FA368B" />
        </svg>
    );
};

export default LogoBirdsText;
