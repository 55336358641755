import { useEffect, useState } from "react";
import { currentPositionPromise } from '@/utils/currentPosition';

export default function useUserLocation() {
    const [currentPosition, setCurrentPosition] = useState({});

    useEffect(() => {
        let isComponentMounted = true;

        currentPositionPromise.then(({ data = {} }) => {
            if (isComponentMounted) {
                setCurrentPosition(data?.result);
            }
        });

        return () => {
            isComponentMounted = false;
        };
    }, []);

    return currentPosition;
};
