const Vr = ({ className }) => (
    <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <g>
                <path d="M24.4776 10.3267L13.589 0.420625C12.974 -0.140208 12.0276 -0.140208 11.4123 0.420625L0.523127 10.3267C-0.134095 10.9237 -0.176317 11.9428 0.420627 12.5998C0.740072 12.9434 1.18007 13.1242 1.61424 13.1242C2.00618 13.1242 2.39813 12.9856 2.70535 12.7023L12.497 3.78479L22.2948 12.7023C22.952 13.299 23.977 13.2567 24.574 12.5998C25.1773 11.9428 25.1351 10.9237 24.4776 10.3267Z" fill="#797882" />
                <path d="M3.87537 13.1061V23.1875C3.88148 23.5672 4.1287 23.8086 4.3337 23.9231C4.54481 24.0436 4.77398 24.0981 5.0212 24.0981C10.5137 24.0981 14.4812 24.0981 19.9801 24.0981C20.2273 24.0981 20.4565 24.0436 20.6676 23.9231C20.8726 23.8086 21.1137 23.5672 21.1259 23.1875V13.1061L12.4976 5.26196L3.87537 13.1061ZM16.0306 17.2483H8.9462V12.3106H16.0306V17.2483Z" fill="#797882" />
            </g>
        </g>
    </svg>

);

export default Vr;
