import LocationNew from "../icons/LocationNew";

const City = ({ className }) => (
    <LocationNew
        className={className}
        width={20}
        height={20}
        fill="#797882"
    />
    // <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    //     <path d="M19.4444 11.1111V0H5.55556V5.55556H0V25H11.1111V19.4444H13.8889V25H25V11.1111H19.4444ZM5.55556 22.2222H2.77778V19.4444H5.55556V22.2222ZM5.55556 16.6667H2.77778V13.8889H5.55556V16.6667ZM5.55556 11.1111H2.77778V8.33333H5.55556V11.1111ZM11.1111 16.6667H8.33333V13.8889H11.1111V16.6667ZM11.1111 11.1111H8.33333V8.33333H11.1111V11.1111ZM11.1111 5.55556H8.33333V2.77778H11.1111V5.55556ZM16.6667 16.6667H13.8889V13.8889H16.6667V16.6667ZM16.6667 11.1111H13.8889V8.33333H16.6667V11.1111ZM16.6667 5.55556H13.8889V2.77778H16.6667V5.55556ZM22.2222 22.2222H19.4444V19.4444H22.2222V22.2222ZM22.2222 16.6667H19.4444V13.8889H22.2222V16.6667Z" fill="#797882" />
    // </svg>
);

export default City;
