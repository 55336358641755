import { memo } from "react";
import Airport from "./Airport";
import Hotel from "./Hotel";
import Vr from "./Vr";
import Poi from "./Poi";
import Attraction from "./Attraction";
import { DestinationTypeEnum } from "@/static/contants";
import City from "./City";
import Country from "./Country";
import State from "./State";

const AutosuggestIcon = ({ className, type }) => {
    const RenderIcon = ({
        [DestinationTypeEnum.AIRPORT]: Airport,
        [DestinationTypeEnum.HOTEL]: Hotel,
        [DestinationTypeEnum.VR]: Vr,
        [DestinationTypeEnum.POI]: Poi,
        [DestinationTypeEnum.NEIGHBOURHOOD]: Attraction,
        [DestinationTypeEnum.CITY]: City,
        [DestinationTypeEnum.COUNTRY]: Country,
        [DestinationTypeEnum.STATE]: State
    }[type]) ?? Attraction;

    return (
        <RenderIcon className={className} />
    );
};

export default memo(AutosuggestIcon);
