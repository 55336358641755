import React, { memo } from 'react';

const LocationNew = ({
    className,
    width = 15,
    height = 20,
    fill = '#64748B'
}) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
    >
        <path d="M7.41667 0C3.74074 0 0.75 2.99074 0.75 6.66667C0.75 7.77018 1.0259 8.86432 1.55039 9.83479L7.0521 19.7851C7.12534 19.9178 7.26491 20 7.41667 20C7.56842 20 7.708 19.9178 7.78124 19.7851L13.285 9.8315C13.8074 8.86432 14.0833 7.77014 14.0833 6.66663C14.0833 2.99074 11.0926 0 7.41667 0ZM7.41667 9.99998C5.5787 9.99998 4.08335 8.50463 4.08335 6.66667C4.08335 4.8287 5.5787 3.33335 7.41667 3.33335C9.25463 3.33335 10.75 4.8287 10.75 6.66667C10.75 8.50463 9.25463 9.99998 7.41667 9.99998Z" fill={fill} />
    </svg>
);

export default memo(LocationNew);


