import React, { forwardRef, memo, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const stylesAbsolute = {
    position: 'absolute'
};

const stylesFixed = {
    position: 'fixed'
};

const positionStrategies = new Map([
    ['absolute', stylesAbsolute],
    ['fixed', stylesFixed]
]);

const Portal = forwardRef(({
    positionType,
    className,
    children,
    appendToElement,
    rootElement,
    rootPortalClassName = 'cn__portal'
}, forwardedRef) => {
    const wrapperRef = useRef(portalAnchorElementFactory(rootElement, rootPortalClassName));

    useEffect(() => {
        const { current: wrapperEl } = wrapperRef;
        const mountTo = appendToElement ?? document.body;
        mountTo.appendChild(wrapperEl);
        return () => {
            wrapperEl.remove();
        };
    }, [appendToElement]);

    const getPositionType = () =>
        positionStrategies.has(positionType)
            ? positionStrategies.get(positionType)
            : {};

    return createPortal(
        <div
            ref={forwardedRef}
            style={getPositionType()}
            className={className}
        >
            {children}
        </div>,
        wrapperRef.current
    );
});

export default memo(Portal);

function portalAnchorElementFactory(rootElement, rootPortalClassName) {
    const portalAnchor = rootElement instanceof Element
        ? rootElement
        : document.createElement('div');

    portalAnchor.classList.add(rootPortalClassName);

    return portalAnchor;
}
