import React, { memo, useEffect, useRef } from "react";
// import { createPortal } from "react-dom";
import PropTypes from 'prop-types';

import Portal from '../Portal';

// const stylesAbsolute = {
//     position: 'absolute'
// };

// const stylesFixed = {
//     position: 'fixed'
// };

const Popover = ({
    children,
    className,
    rootClassName,
    rootPortalClassName,
    appendToElement,
    anchorElementRef,
    scrollElementRef,
    positionType,
    anchorAlign: { horizontal },
    offset: { x, y }
}) => {
    const popoverRef = useRef(null);

    const elem = useRef(document.createElement('div'));

    const windowInnerWidth = useRef(window.innerWidth);

    useEffect(() => {
        const { current: el } = elem;
        if (rootClassName) {
            el.classList.add(rootClassName);
        }

        const alignPopover = () => {
            const { current: anchorElement } = anchorElementRef;
            if (anchorElement) {
                const rect = anchorElement.getBoundingClientRect();
                const { current: popoverEl } = popoverRef;
                if ((horizontal === 'left') && (((rect.left + (x ?? 0)) + popoverEl.firstElementChild?.offsetWidth) > windowInnerWidth.current)) {
                    popoverEl.style.left = '';
                    popoverEl.style.right = 0;
                } else if (rect.left < 0) {
                    popoverEl.style.left = 0;
                    popoverEl.style.right = '';
                } else {
                    popoverEl.style.right = '';
                    if (horizontal === 'left') {
                        popoverEl.style.left = `${rect.x + (x ?? 0)}px`;
                    }
                }
            }
        };

        const updatePosition = () => {
            windowInnerWidth.current = window.innerWidth;
            const { current: anchorElement } = anchorElementRef;
            if (anchorElement) {
                const rect = anchorElement.getBoundingClientRect();
                const { current: popoverEl } = popoverRef;
                popoverEl.style.left = `${
                    horizontal === 'left'
                        ? rect.x + (x ?? 0)
                        : (rect.right - popoverEl.offsetWidth) + (x ?? 0)}px`;
                popoverEl.style.top = `${rect.y +
                    (positionType === 'absolute' ? window.scrollY : 0 ) +
                    (anchorElement.offsetHeight ?? anchorElement.clientHeight) +
                    (y ?? 0)}px`;
            }
            alignPopover();
        };

        updatePosition();

        scrollElementRef?.current.addEventListener('scroll', updatePosition);

        window.addEventListener('resize', updatePosition);
        // appendToElement.appendChild(el);

        alignPopover();

        return () => {
            // el.remove();
            window.removeEventListener('resize', updatePosition);
            scrollElementRef?.current?.removeEventListener('scroll', updatePosition);
        };
    }, [appendToElement, className, rootClassName, scrollElementRef, y, x, horizontal]);

    // return createPortal(
    //     <div ref={popoverRef} style={{ ...{ absolute: stylesAbsolute, fixed: stylesFixed }[positionType] }} className={className}>
    //         {children}
    //     </div>,
    //     elem.current
    // );
    return (
        <Portal
            ref={popoverRef}
            positionType={positionType}
            className={className}
            appendToElement={appendToElement}
            rootElement={elem.current}
            rootPortalClassName={rootPortalClassName}
        >
            {children}
        </Portal>
    );
};

Popover.defaultProps = {
    offset: {
        x: 0,
        y: 0
    },
    anchorAlign: {
        horizontal: 'left'
    },
    positionType: 'absolute'
};

Popover.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]).isRequired,
    className: PropTypes.string,
    elemClassName: PropTypes.string,
    appendToElement: PropTypes.object.isRequired,
    anchorElementRef: PropTypes.object.isRequired,
    scrollElementRef: PropTypes.object,
    offset: PropTypes.exact({
        x: PropTypes.number,
        y: PropTypes.number
    })
};

export default memo(Popover);