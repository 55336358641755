import clsx from "clsx";
import styles from './OmniAside.module.scss';
import Chip from "@mui/material/Chip";
import { Type } from "./helpers";

export default function OmniAside({ className, type, stars, grs, grs_adjective, ...props }) {
    if (type && ((!stars && !grs) || (grs < 6))) {
        return (
            <Chip
                label={Type.getType(type)}
                size='small'
                variant='outlined'
                sx={{
                    alignSelf: 'center',
                    display: 'flex',
                    borderColor: 'var(--color-slate-grey)',
                    color: 'var(--color-slate-grey)',
                    textTransform: 'capitalize',
                    fontFamily: 'var(--primary-font)',
                    fontSize: 12,
                    fontWeight: 600,
                    lineHeight: '150%',
                    letterSpacing: .12,
                    minWidth: 100,
                    width: '100%',
                    '& .MuiChip-label': {
                        textAlign: 'center'
                    },
                    ...props.sx
                }}
            />
        );
    }

    return (
        <div className={clsx(!!className && className, styles.omnisearch_aside)}>
            {!isNaN(grs) && (
                <div className={styles.omnisearch_grs}>
                    <span className={styles.omnisearch_grs_number}>
                        {grs < 10
                            ? Number.parseFloat(grs).toFixed(1)
                            : Math.round(grs)}
                    </span>
                    <p className={styles.omnisearch_grs_text}>
                        {grs_adjective}
                    </p>
                </div>
            )}
             {(Number(stars) > 0) && (
                <div className={clsx('omnisearch_stars', styles.omnisearch_stars)}>
                   {Math.floor(stars)}-star
                   <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path d="M7.93682 1.98196C8.15013 1.34502 9.05104 1.34502 9.26435 1.98196L10.276 5.00269C10.3708 5.28585 10.6349 5.47767 10.9335 5.48036L14.119 5.50904C14.7907 5.51509 15.069 6.3719 14.5292 6.7716L11.9689 8.6672C11.7289 8.84489 11.6281 9.15527 11.7178 9.44009L12.6749 12.4785C12.8767 13.1192 12.1479 13.6488 11.6009 13.2589L9.00692 11.4097C8.76376 11.2363 8.43741 11.2363 8.19425 11.4097L5.60027 13.2589C5.05331 13.6488 4.32446 13.1192 4.52627 12.4785L5.48337 9.44009C5.57308 9.15527 5.47224 8.84489 5.23224 8.6672L2.67197 6.7716C2.13213 6.3719 2.41052 5.51509 3.0822 5.50904L6.2677 5.48036C6.56631 5.47767 6.83033 5.28585 6.92517 5.00269L7.93682 1.98196Z" fill="#EFB008"/>
                    </svg>
                </div>
            )}
        </div>
    );
}
