import { useEffect, useRef, useState } from "react";

export default function useResponsiveResource(mediaQueries = new Map(), deps = []) {
    const mediaQueriesRef = useRef(mediaQueries);
    mediaQueriesRef.current = mediaQueries;

    const [resource, setResource] = useState();

    useEffect(() => {
        mediaQueriesRef.current.forEach((getResource, mediaQuery) => {
            const mediaQueryList = window.matchMedia(mediaQuery);
            mediaQueryList.onchange = event => {
                if (event.matches) {
                    setResource(getResource);
                }
            };

            if (mediaQueryList.matches) {
                setResource(getResource);
            }
        });
    }, deps);

    return resource;
};
